import React, { useState } from "react";
import SignUp from "../auth/signUp";
import "./PortfolioOverlay.css";

const PortfolioOverlay = () => {
  const [showSignUp, setShowSignUp] = useState(false);

  return (
    <>
      <button className="portfolio-overlay" onClick={() => setShowSignUp(true)}>
        <div className="portfolio-overlay-content">
          <p>
            🔒 Your positions will disappear. Log In or Sign Up for a FREE account
            to save them.
          </p>
        </div>
      </button>
      {showSignUp && <SignUp onClose={() => setShowSignUp(false)} />}
    </>
  );
};

export default PortfolioOverlay;
