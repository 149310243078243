import React from 'react';
import './MobileMenuToggle.css';

const MobileMenuToggle = ({ isOpen, toggleMenu }) => {
  return (
    <button 
      className={`mobile-menu-toggle ${isOpen ? 'open' : ''}`}
      onClick={toggleMenu}
      aria-label="Toggle menu"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default MobileMenuToggle; 