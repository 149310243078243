import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchBar.css';

const SearchBar = () => {
  const [symbol, setSymbol] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (symbol.trim()) {
      navigate(`/stocks/${symbol.trim().toUpperCase()}`);
    }
  };

  return (
    <form className="search-container" onSubmit={handleSubmit}>
      <svg 
        className="search-icon" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
      <input
        type="text"
        className="search-bar"
        placeholder="Stock, ETF, or Crypto symbol..."
        value={symbol}
        onChange={(e) => setSymbol(e.target.value)}
      />
    </form>
  );
};

export default SearchBar; 