import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import Home from "./pages/Home";
import Indexes from "./pages/Indexes";
import Etfs from "./pages/Etfs";
import Stocks from "./pages/Stocks";
import StockDetail from "./components/StockDetail";

import { initGA, trackPageView } from './utils/analytics';

// Create a wrapper component that handles analytics
const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return (
    <div className="app-container">
      <div className="main-content">
        <Navbar />
        <div className="app-container">
          <SideNav />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/indexes" element={<Indexes />} />
              <Route path="/etfs" element={<Etfs />} />
              <Route path="/stocks" element={<Stocks />} />
              <Route path="/stocks/:symbol" element={<StockDetail />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </main>
        </div>
      </div>
      <div className="footer-wrapper">
        <Footer />
      </div>
    </div>
  );
};

const App = () => {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
