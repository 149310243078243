import { useState } from "react";
import { supabase } from "../../supabaseClient";
import "./signIn.css";
import GoogleSignIn from "./GoogleSignIn";

const SignIn = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError(null);
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) setError(error.message);
    else {
      alert("Signed in successfully");
      onClose();
      window.location.reload();
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter your email to reset your password.");
      return;
    }
    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) {
      setError(error.message);
    } else {
      setMessage("Check your email for the password reset link.");
    }
  };

  return (
    <div className="sign-in-modal" onClick={onClose}>
      <div className="sign-in-modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          ×
        </button>

        {showResetPassword ? (
          <>
            <h2 className="modal-title">Reset Password</h2>
            <p>Enter your email address to receive a password reset link.</p>
            <form onSubmit={handlePasswordReset} className="sign-in-form">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <button type="submit">Send Reset Link</button>
              {error && <p className="error-text">{error}</p>}
              {message && <p className="success-text">{message}</p>}
            </form>
          </>
        ) : (
          <>
            <h2 className="modal-title">Log in to your account</h2>
            <form onSubmit={handleSignIn} className="sign-in-form">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              <button type="submit">Sign In</button>
              {error && <p className="error-text">{error}</p>}
              {message && <p className="success-text">{message}</p>}
              <button
                type="button"
                className="forgot-password"
                onClick={() => setShowResetPassword(true)}
              >
                Forgot Password?
              </button>
            </form>
            <div className="divider"></div>
            <GoogleSignIn />
          </>
        )}
      </div>
    </div>
  );
};

export default SignIn;
