import React from "react";
import { Link } from "react-router-dom";
import "./SideNav.css";

const SideNav = ({ isOpen, toggleMenu }) => {
  const handleLinkClick = () => {
    toggleMenu();
  };

  return (
    <nav className={`sidenav ${isOpen ? 'open' : ''}`}>
      <ul>
        <li>
          <Link to="/" onClick={handleLinkClick}>Home</Link>
        </li>
        <li>
          <Link to="/indexes" onClick={handleLinkClick}>Indexes</Link>
        </li>
        <li>
          <Link to="/etfs" onClick={handleLinkClick}>ETFs</Link>
        </li>
        <li>
          <Link to="/stocks" onClick={handleLinkClick}>Stocks</Link>
        </li>
        <li>
          <Link to="/crypto" onClick={handleLinkClick}>Crypto</Link>
        </li>
        <li>
          <Link to="/bonds" onClick={handleLinkClick}>Bonds</Link>
        </li>
        <li>
          <Link to="/commodities" onClick={handleLinkClick}>Commodities</Link>
        </li>
        <li>
          <Link to="/themes" onClick={handleLinkClick}>Themes</Link>
        </li>
        <li>
          <Link to="/newsletter" onClick={handleLinkClick}>Newsletter</Link>
        </li>
        <li>
          <Link to="/watchlist" onClick={handleLinkClick}>Watchlist</Link>
        </li>
        <li>
          <Link to="/portfolio" onClick={handleLinkClick}>My Portfolio</Link>
        </li>
      </ul>
    </nav>
  );
};

export default SideNav;
