import React, { useEffect, useState } from "react";
import logo from "./media_assets/full_logo_2.png";
import mobileLogo from "./media_assets/mobile_logo.png";
import "./Navbar.css";
import SignIn from "./auth/signIn.js";
import SignUp from "./auth/signUp.js";
import SignOut from "./auth/signOut.js";
import { supabase } from "../supabaseClient";
import SearchBar from "./SearchBar.js";
import MobileMenuToggle from "./MobileMenuToggle";
import SideNav from "./SideNav";

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser(); // Fetch the authenticated user if exists
      setUser(user);
    };

    fetchUser();
  }, []);

  const toggleSignInModal = () => setIsSignInModalOpen(!isSignInModalOpen);
  const toggleSignUpModal = () => setIsSignUpModalOpen(!isSignUpModalOpen);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <MobileMenuToggle isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />
          <div className="logo">
            <a href="https://stockdrawdowns.com">
              <img src={logo} alt="Logo" className="logo-img desktop-logo" />
              <img src={mobileLogo} alt="Logo" className="logo-img mobile-logo" />
            </a>
          </div>
          <SearchBar />
          <div className="auth-links">
            {user ? (
              <SignOut />
            ) : (
              <>
                <button className="login-button" onClick={toggleSignInModal}>
                  Log In
                </button>
                <button className="signup-button" onClick={toggleSignUpModal}>
                  Sign Up
                </button>
              </>
            )}
          </div>
        </div>
        {isSignInModalOpen && <SignIn closeModal={toggleSignInModal} />}
        {isSignUpModalOpen && <SignUp closeModal={toggleSignUpModal} />}
      </nav>
      <SideNav isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />
    </>
  );
};

export default Navbar;
