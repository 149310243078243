import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import DrawdownChart from './DrawdownChart';
import './StockDetail.css';
import { trackEvent } from '../utils/analytics';

const StockDetail = () => {
  const { symbol } = useParams();
  const [companyData, setCompanyData] = useState(null);
  const [priceData, setPriceData] = useState({
    currentPrice: null,
    priceChange: null,
    percentChange: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch company details
        const { data: companyDetails, error: companyError } = await supabase.functions.invoke(
          'getStockDetails',
          {
            body: JSON.stringify({ symbol: symbol.toUpperCase() }),
          }
        );

        if (companyError) throw companyError;
        setCompanyData(companyDetails);

        // Fetch current price data
        const { data: priceDetails, error: priceError } = await supabase.functions.invoke(
          'getDailyPriceChange',
          {
            body: JSON.stringify({ symbol: symbol.toUpperCase() }),
          }
        );

        if (priceError) throw priceError;
        setPriceData(priceDetails);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    if (symbol) {
      fetchData();
    }
  }, [symbol]);

  useEffect(() => {
    // Track stock detail view
    trackEvent('Stock Detail', 'Detail Viewed', symbol);
  }, [symbol]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!companyData) return <div>No company data found</div>;

  const getPriceChangeColor = (change) => {
    return change >= 0 ? 'var(--success)' : 'var(--error)';
  };

  return (
    <div className="stock-detail">
      <div className="company-header">
        <div className="name-symbol">
          <h1>{companyData.name} <span>({companyData.symbol})</span></h1>
        </div>
        <div className="price-info">
          <span className="current-price">${priceData.currentPrice}</span>
          <span 
            className="price-change"
            style={{ color: getPriceChangeColor(priceData.priceChange) }}
          >
            {priceData.priceChange >= 0 ? '+' : ''}
            {priceData.priceChange.toFixed(2)} ({priceData.percentChange.toFixed(2)}%)
          </span>
        </div>
      </div>
      <DrawdownChart symbol={symbol} />
    </div>
  );
};

export default StockDetail; 