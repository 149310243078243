import React from "react";

import StockDrawdown from "../components/StockDrawdown.js";
import WorldMap from "../components/WorldMap.js";

const Indexes = () => {
  const stockData = {
    China: { performance: -5, ticker: "$FXI" },
    Canada: { performance: -3 },
    Russia: { performance: -2.1 },
    BRA: { performance: -7 },
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <h1 className="h-1">US Indexes</h1>
        <div className="stock-drawdown-container">
          <StockDrawdown index="S&P500" ticker="SPY"></StockDrawdown>
          <StockDrawdown index="Nasdaq 100" ticker="QQQ"></StockDrawdown>
          <StockDrawdown index="Dow Jones" ticker="DIA"></StockDrawdown>
          <StockDrawdown index="Russell 2000" ticker="IWM"></StockDrawdown>
        </div>
        <p></p>
        <div className="stock-drawdown-container">
          <StockDrawdown index="US Growth" ticker="IUSG"></StockDrawdown>
          <StockDrawdown index="US Value " ticker="IUSV"></StockDrawdown>
          <StockDrawdown index="Equal Weight" ticker="RSP"></StockDrawdown>
          <StockDrawdown index="VIX" ticker="VXX"></StockDrawdown>
        </div>
      </div>
      <div className="page-header">
        <h1 className="h-1">International Indexes</h1>
        <WorldMap stockData={stockData} />
      </div>
    </div>
  );
};

export default Indexes;
