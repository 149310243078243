import React, { useState } from "react";
import SignUp from "../auth/signUp";
import "./WatchlistOverlay.css";

const WatchlistOverlay = () => {
  const [showSignUp, setShowSignUp] = useState(false);

  return (
    <>
      <button className="watchlist-overlay" onClick={() => setShowSignUp(true)}>
        <div className="watchlist-overlay-content">
          <p>
            🔒 Your watchlist will disappear. Log In or Sign Up for a FREE account
            to save it.
          </p>
        </div>
      </button>
      {showSignUp && <SignUp onClose={() => setShowSignUp(false)} />}
    </>
  );
};

export default WatchlistOverlay; 