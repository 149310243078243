import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./StockDrawdown.css";
import { supabase } from "../supabaseClient";

const StockDrawdown = ({ index, ticker }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    lastPrice: null,
    high52week: null,
    drawdown: null,
    retracement: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = () => {
    navigate(`/stocks/${ticker}`);
  };

  useEffect(() => {
    const fetchStockData = async () => {
      setLoading(true);
      setError(null);
      try {
        const { data, error } = await supabase.functions.invoke(
          "get52WeekHigh",
          {
            body: JSON.stringify({ symbol: ticker }),
          }
        );

        if (error) throw error;

        const lastPrice = JSON.parse(data).lastPrice.toFixed(2);
        const high52Week = JSON.parse(data).high52Week.toFixed(2);

        // Calculate drawdown and retracement
        const drawdown = ((high52Week - lastPrice) / high52Week) * 100;
        const retracement = ((high52Week / lastPrice - 1) * 100).toFixed(2);

        // Update state with received data
        setData({
          lastPrice,
          high52Week,
          drawdown: drawdown.toFixed(2),
          retracement,
        });
      } catch (error) {
        setError("Error fetching stock data.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    // Only fetch if a ticker is provided
    if (ticker) {
      fetchStockData();
    }
  }, [ticker]);

  if (loading) {
    return <p>Loading...</p>; // Show a loading state while fetching data
  }

  if (error) {
    return <p>{error}</p>; // Show an error message if there is one
  }

  let backgroundColor = "#fff"; // Default background
  if (data.lastPrice && data.high52Week) {
    const pricePercentage = (data.lastPrice / data.high52Week) * 100;

    if (pricePercentage >= 100) {
      backgroundColor = "lightgreen"; // Green if at or above 100%
    } else if (pricePercentage >= 80) {
      // Linear interpolation for red shade between 80% and 100%
      const intensity = ((pricePercentage - 80) / 20) * 255;
      backgroundColor = `rgb(255, ${Math.floor(intensity)}, ${Math.floor(
        intensity
      )})`;
    } else {
      backgroundColor = "rgb(255, 0, 0)"; // Darkest red if below 80%
    }
  }

  return (
    <div 
      className="stock-drawdown" 
      style={{ backgroundColor }}
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className="header">
        <h2>{index}</h2>
        <span className="ticker">${ticker}</span>
      </div>
      <div className="drawdown">
        <span className="value">{data.drawdown}%</span>
        <span className="label">Drawdown</span>
      </div>
      <div className="metrics">
        <p>
          <span className="label">Current</span>
          <span className="value">${data.lastPrice}</span>
        </p>
        <p>
          <span className="label">52W High</span>
          <span className="value">${data.high52Week}</span>
        </p>
      </div>
    </div>
  );
};

export default StockDrawdown;
