import React, { useState, useEffect } from "react";

import StockDrawdown from "../components/StockDrawdown.js";
import QuotesTable from "../components/QuotesTable.js";

import { supabase } from "../supabaseClient";

const Etfs = () => {
  const [etfTableData, setEtfTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const etfData = await supabase.functions.invoke("getEtfData", {
          body: null,
        });
        setEtfTableData(etfData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("Updated data:", etfTableData); // Log the state whenever it updates
  }, [etfTableData]);

  return (
    <div className="page-container">
      <div className="page-header">
        <h1 className="h-1">ETFs</h1>
        <div className="stock-drawdown-container">
          <StockDrawdown
            index="Communication Services"
            ticker="XLC"
          ></StockDrawdown>
          <StockDrawdown
            index="Consumer Discretionary"
            ticker="XLY"
          ></StockDrawdown>
          <StockDrawdown index="Consumer Staples" ticker="XLP"></StockDrawdown>
          <StockDrawdown index="Energy" ticker="XLE"></StockDrawdown>
          <StockDrawdown index="Financials" ticker="XLF"></StockDrawdown>
          <StockDrawdown index="Health Care" ticker="XLV"></StockDrawdown>
        </div>
        <p></p>
        <div className="stock-drawdown-container">
          <StockDrawdown index="Industrials" ticker="XLI"></StockDrawdown>
          <StockDrawdown index="Materials" ticker="XLB"></StockDrawdown>
          <StockDrawdown index="Real Estate" ticker="XLRE"></StockDrawdown>
          <StockDrawdown index="Technology" ticker="XLK"></StockDrawdown>
          <StockDrawdown index="Utilities" ticker="XLU"></StockDrawdown>
        </div>
      </div>
      <h1 className="h-1">Top 50 ETFs by AUM</h1>
      {etfTableData && <QuotesTable data={etfTableData} />}
    </div>
  );
};

export default Etfs;
