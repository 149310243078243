import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { supabase } from '../supabaseClient';
import './DrawdownChart.css';
import watermarkLogo from './media_assets/watermark_logo.png';

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const timeRanges = [
  { label: 'YTD', value: 'ytd' },
  { label: '1Y', value: '1y' },
  { label: '5Y', value: '5y' },
  { label: 'Max', value: 'max' }
];

const createGradient = (ctx, maxDrawdown) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, 400);
  gradient.addColorStop(0, 'rgba(255, 0, 0, 0.1)');
  gradient.addColorStop(1, 'rgba(255, 0, 0, 0.4)');
  return gradient;
};

const DrawdownChart = ({ symbol }) => {
  const [selectedRange, setSelectedRange] = useState('1y');
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [drawdownStats, setDrawdownStats] = useState({
    current: null,
    max: null
  });

  useEffect(() => {
    const fetchDrawdownData = async () => {
      setLoading(true);
      setError(null);
      try {
        const { data: responseData, error: responseError } = await supabase.functions.invoke(
          'getDrawdownHistory',
          {
            body: JSON.stringify({ 
              symbol: symbol.toUpperCase(),
              timeRange: selectedRange 
            }),
          }
        );

        if (responseError) throw new Error(responseError.message || 'Failed to fetch drawdown data');
        if (!responseData || !responseData.dates || !responseData.drawdowns) {
          throw new Error('Invalid data received from server');
        }

        const maxDrawdown = Math.max(...responseData.drawdowns);
        const currentDrawdown = responseData.drawdowns[responseData.drawdowns.length - 1];
        
        setDrawdownStats({
          current: currentDrawdown.toFixed(2),
          max: maxDrawdown.toFixed(2)
        });

        const chartConfig = {
          labels: responseData.dates,
          datasets: [
            {
              label: 'Drawdown',
              data: responseData.dates.map((date, index) => ({
                x: new Date(date),
                y: responseData.drawdowns[index]
              })),
              borderColor: 'rgb(220, 53, 69)',
              borderWidth: 2,
              backgroundColor: function(context) {
                const chart = context.chart;
                const {ctx, chartArea} = chart;
                if (!chartArea) return null;
                return createGradient(ctx, maxDrawdown);
              },
              fill: true,
              pointRadius: 0,
              tension: 0,
            }
          ]
        };

        setChartData(chartConfig);
      } catch (err) {
        console.error('Error in fetchDrawdownData:', err);
        setError(err.message || 'Failed to load chart data');
      } finally {
        setLoading(false);
      }
    };

    if (symbol) {
      fetchDrawdownData();
    }
  }, [symbol, selectedRange]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        titleColor: '#000',
        bodyColor: '#000',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        padding: 10,
        callbacks: {
          title: function(context) {
            return new Date(context[0].raw.x).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            });
          },
          label: function(context) {
            return `Drawdown: ${context.raw.y.toFixed(2)}%`;
          }
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: selectedRange === '5y' || selectedRange === 'max' ? 'month' : 'day',
          displayFormats: {
            day: 'MMM d',
            month: 'MMM yyyy'
          }
        },
        grid: {
          display: false
        },
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: selectedRange === '5y' || selectedRange === 'max' ? 12 : 8,
          font: {
            size: 11
          }
        }
      },
      y: {
        reverse: true,
        min: 0,
        max: chartData?.datasets[0]?.data ? 
          Math.min(Math.ceil(Math.max(...chartData.datasets[0].data.map(d => d.y)) * 1.2), 100) : 100,
        ticks: {
          callback: function(value) {
            return value + '%';
          }
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        }
      }
    }
  };

  return (
    <>
      <div className="drawdown-summary">
        <div className="summary-stat">
          <span className="stat-label">Current drawdown ({selectedRange.toUpperCase()}):</span>
          <span className="stat-value">{drawdownStats.current}%</span>
        </div>
        <div className="summary-stat">
          <span className="stat-label">Max drawdown ({selectedRange.toUpperCase()}):</span>
          <span className="stat-value">{drawdownStats.max}%</span>
        </div>
      </div>
      <div className="drawdown-chart">
        <div className="time-range-selector">
          {timeRanges.map((range) => (
            <button
              key={range.value}
              className={`range-button ${selectedRange === range.value ? 'active' : ''}`}
              onClick={() => setSelectedRange(range.value)}
            >
              {range.label}
            </button>
          ))}
        </div>
        <div className="chart-container">
          <img src={watermarkLogo} alt="Logo" className="chart-watermark" />
          {loading && <div className="loading">Loading chart data...</div>}
          {error && <div className="error">Error: {error}</div>}
          {!loading && !error && chartData && <Line options={options} data={chartData} />}
        </div>
      </div>
    </>
  );
};

export default DrawdownChart; 